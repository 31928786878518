.App {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    grid-template-columns: 0px auto 0px;
    grid-template-rows: 20px 1fr 1fr 1fr 20px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

/*----------------------------*/

body {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    width: 100%;
    height: 100%;
}

.centerBox {
    display: grid;
    grid-column: 2;
    grid-row: 2;
    margin: 0 auto;
    width: 100%;
    height: 50%;
    background-color: transparent;
    z-index: 1;

}

.centerBox2 {
    display: grid;
    grid-column: 2;
    grid-row: 3;
    margin: 0 auto;
    width: 100%;
    height: 50%;
    background-color: transparent;
    z-index: 1;

}

.centerBox3 {
    display: grid;
    grid-column: 2;
    grid-row: 4;
    margin: 0 auto;
    width: 100%;
    height: 50%;
    background-color: transparent;
    align-items: center;
    justify-items: center;
    z-index: 1;

}

.firstLetter {
    color: #DDDDDD;
    font-size: min(16vw, 10rem);
}

.title {
    color: #DDDDDD;
    font-size: min(12vw, 6rem);
    margin: auto;
    text-align: center;
    z-index: 1;

}

.subtitle {
    margin: auto;
    color: #CCCCCC;
    font-size: min(5vw, 1rem);
    letter-spacing: min(5vw, 2rem);
    text-align: center;
    z-index: 1;

}

.normal {
    margin: auto;
    color: #CCCCCC;
    font-size: min(5vw, 1rem);
    text-align: center;
}

button {
    margin: auto;
}

input[type="text"], input[type="password"], textarea {
    background-color: #000000;
    color: #CCCCCC;
    font-size: min(vw, 1rem);
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: min(30vw, 10rem);
    margin: 10px;
    margin-top: 40px;
}

.login-form {
    margin: auto;
    z-index: 1;
}

.background-video {
    height: 100%;
    width: 100%;
    float: left;
    top: 0;
    padding: 0px;
    position: fixed;
    object-fit: fill;
    opacity: 50%
}

.background-video-2 {
    height: 100%;
    width: 100%;
    float: left;
    top: 0;
    padding: 0px;
    position: fixed;
    object-fit: fill;
    opacity: 20%
}
